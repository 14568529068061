html, body {
  min-height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  


}

@font-face {
  font-family: "sofachrome";
  src: local("sofachrome"),
    url("./fonts/sofachrome.rg-regular.ttf") format("truetype");
}

@font-face {
  font-family: "ethnocentric";
  src: local("ethnocentric"),
    url("./fonts/Ethnocentric\ Light.otf") format("opentype");
}

@font-face {
  font-family: "ethnocentric-ultra-light";
  src: local("ethnocentric-ultra-light"),
    url("./fonts/Ethnocentric\ Ultra\ Light.otf") format("opentype");
}

@font-face {
  font-family: "ethnocentric-light";
  src: local("ethnocentric-light"),
    url("./fonts/Ethnocentric\ Light.otf") format("opentype");
}


@font-face {
  font-family: "ethnocentric-bold";
  src: local("ethnocentric-bold"),
    url("./fonts/Ethnocentric\ Bold.otf") format("opentype");
}

@font-face {
  font-family: "besan";
  src: local("besan"),
    url("./fonts/Besan.otf") format("opentype");
}

@font-face {
  font-family: "gotham";
  src: local("gotham"),
    url("./fonts/GothamMedium.ttf") format("opentype");
}
@font-face {
  font-family: "gotham-book";
  src: local("gotham-book"),
    url("./fonts/GothamBook.ttf") format("opentype");
}

@font-face {
  font-family: "gotham-bold";
  src: local("gotham-bold"),
    url("./fonts/Gotham-Bold.otf") format("opentype");
}

@font-face {
  font-family: "gotham-black";
  src: local("gotham-black"),
    url("./fonts/Gotham-Black.otf") format("opentype");
}   


/* canvas {
  min-height: 100vh;
  min-width: 100vw;
  background: transparent;
} */




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
